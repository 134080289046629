<template>
  <div class="user-master-list">
    <v-divider></v-divider>
    <div class="content-area pa-8">
      <div class="text-h5 blue--text text--darken-4">ユーザーマスタ</div>

      <v-row class="py-4">
        <v-col cols="4">
          <v-select
            v-model="searchDepartment"
            label="所属"
            :items="departmentsList || []"
            item-text="departmentName"
            item-value="id"
            outlined
            multiple
            small-chips
            deletable-chips
            hide-details
            clearable
            style="background-color: white"
            @change="fetchUserData"
          ></v-select>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="filterText"
            append-icon="mdi-magnify"
            label="フィルタ"
            single-line
            hide-details
          ></v-text-field>      
        </v-col>
        <v-col cols="4" class="d-flex justify-end">
          <v-btn color="btAccent" dark depressed @click="editItem(null)">
            <v-icon class="pr-1">mdi-plus-circle-outline</v-icon>
            新規登録
          </v-btn>
        </v-col>
      </v-row>

      <v-data-table
        class="data-table"
        :headers="headers"
        :items="items"
        :search="filterText"
        style="background-color: transparent"
        no-data-text="該当データ無し"
        no-results-text="該当データ無し"
        :footer-props="{
          itemsPerPageText: '',
          itemsPerPageAllText: '全件',
          pageText: '{2}件中、{0}-{1}'

        }"
      >
        <template v-slot:[`item.userImageUrl`]="{ item }">
          <v-avatar :color="item.userColor" size="36">
            <img v-if="item.userImageUrl"
              :src="item.userImageUrl"
              :alt="iconLabel(item)"
            >
            <span v-else class="white--text text-h8">{{iconLabel(item)}}</span>
            {{item.givenName}}
          </v-avatar>
        </template>

        <template v-slot:[`item.superUser`]="{ item }">
          <div v-if="item.superUser" class="text-center">〇</div>
        </template>
        <template v-slot:[`item.partner`]="{ item }">
          <div v-if="item.partner" class="text-center">〇</div>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            class="mr-2"
            @click="editItem(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
            @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </template>

      </v-data-table>
    </div>

    <user-edit-dialog v-model="editDialog" :userData="editTarget" :add="editType == 'add'" @ok="fetchUserData()"></user-edit-dialog>

    <v-dialog v-model="infoDialog" max-width="500px">
      <v-card>
        <v-card-title class="text-subtitle-2">ユーザーを削除しますか？</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="mx-2 mb-3"
            text
            width="190"
            @click="infoDialog = !infoDialog"
            >キャンセル
          </v-btn>
          <v-btn
            color="btAccent"
            class="white--text mx-2 mb-3"
            width="190"
            depressed
            @click="deleteOk"
            >削除</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import {RepositoryFactory} from '../../api/RepositoryFactory'
import UserEditDialog from '../../components/atomic/organisms/master/UserEditDialog'

export default {
  components: {
    UserEditDialog,
  },
  data() {
    return {
      headers: [
        { text: 'ユーザーID', value: 'userId' },
        { text: 'アイコン', value: 'userImageUrl', filterable: false },
        { text: 'ユーザー名（姓）', value: 'lastName' },
        { text: 'ユーザー名（名）', value: 'firstName' },
        { text: '所属', value: 'departmentName', filterable: false },
        { text: '役割', value: 'position', filterable: false },
        { text: 'メールアドレス', value: 'loginEmail' },
        { text: 'スーパーユーザー', value: 'superUser', filterable: false },
        { text: 'パートナー', value: 'partner', filterable: false },
        { text: 'アクション', value: 'actions', sortable: false },
      ],
      items: [],
      filterText: '',
      searchDepartment: [],
      editDialog: false,
      editTarget: null,
      editType: 'add',

      infoDialog: false,
      deleteTarget: null,

    };
  },
  computed: {
    ...mapGetters(["departmentsList"]),
  },
  async created() {
    if ( !this.departmentsList ) {
      await this.getDepartments();
    }

    await this.fetchUserData();
  },
  methods: {
    ...mapActions([
      "getDepartments",
    ]),
    async fetchUserData() {
      const params = {};

      if ( this.searchDepartment.length > 0 ) {
        params.departmentIds = this.searchDepartment;
      }
      
      const UserRepository = RepositoryFactory.get( 'users' );
      const response = await UserRepository.getUserList( params );
      if (response.data.result && response.data.resultCode === '0000') {
        this.items = response.data.data.users;
      } else {
        this._sendError( response.data.message );
      }
    },

    editItem(item) {
      this.editType = item ? 'edit' : 'add';
      this.editTarget = item;
      this.editDialog = true;
    },
    deleteItem(item) {
      this.infoDialog = true;
      this.deleteTarget = item;
    },

    async deleteOk() {
      if(this.deleteTarget) {
        const UserRepository = RepositoryFactory.get( 'users' );
        await UserRepository.delete( this.deleteTarget.userId );
        this.infoDialog = false;
        await this.fetchUserData();
      }
    },

    iconLabel(item) {
      return item.firstName ? item.firstName.slice(0, 1) + item.lastName.slice(0, 1) : item.lastName.slice(0, 2);
    },

  },
  watch: {
    editDialog(val) {
      if(!val) {
        this.editTarget = null;
      } 
    },
  },

}
</script>
<style lang="scss" scoped>
.user-master-list {
  background-color: #F2F8FF;

  .content-area {
    max-width: 1500px;
    margin-left: auto;
    margin-right: auto;

    .data-table {
      & ::v-deep tbody {
        background-color: white;
      }
    }
  }
}
</style>
